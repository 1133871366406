// Simple store to hold files between component transitions
export default {
  files: [],
  setFiles(files) {
    this.files = files;
  },
  getFiles() {
    return this.files;
  },
  clearFiles() {
    this.files = [];
  },
};
