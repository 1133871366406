<template>
  <div class="results-container">
    <div class="content-columns">
      <!-- Left Column - Input and Filters -->
      <div class="left-column">
        <!-- Input files section -->
        <div class="input-files-section">
          <div class="section-title">
            <i class="fa fa-search"></i>
            <span>Search Images</span>
          </div>
          <div class="input-files-container">
            <div class="input-carousel">
              <div class="input-file-card">
                <div class="input-image-container">
                  <img v-if="files.length > 0" :src="getFilePreviewUrl(files[currentImageIndex])" alt="Input image" class="input-image" />
                  
                  <!-- Image Counter Overlay -->
                  <div class="image-counter" v-if="files.length > 1">
                    {{ currentImageIndex + 1 }}/{{ files.length }}
                  </div>
                  
                  <!-- Navigation Arrows Overlay -->
                  <div class="carousel-navigation left" v-if="files.length > 1" @click="prevImage">
                    <i class="fa fa-chevron-left"></i>
                  </div>
                  
                  <div class="carousel-navigation right" v-if="files.length > 1" @click="nextImage">
                    <i class="fa fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Tab Navigation -->
        <div class="tab-navigation">
          <div
            class="tab-item"
            :class="{ active: selectedTab === 'of' }"
            @click="selectTab('of')"
          >
            <i class="fa fa-star"></i>
            <span>OnlyFans</span>
            <div class="tab-indicator" v-if="loading.of">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
            <div class="tab-count" v-if="values.of?.data">
              {{ values.of.data.length }}
            </div>
          </div>

          <div
            class="tab-item"
            :class="{ active: selectedTab === 'psos' }"
            @click="selectTab('psos')"
          >
            <i class="fa fa-film"></i>
            <span>Adult Movies</span>
            <div class="tab-indicator" v-if="loading.psos">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
            <div class="tab-count" v-if="values.psos?.data">
              {{ values.psos.data.length }}
            </div>
          </div>
        </div>

        <!-- Filter Options -->
        <div class="filter-section">
          <div class="filter-toggle">
            <label class="toggle-label">
              <input type="checkbox" v-model="showOnlyMatches">
              <span class="toggle-switch"></span>
              <span class="toggle-text">Only Exact Matches</span>
            </label>
          </div>
        </div>

        <div class="actions-container left-actions">
          <router-link to="/" class="action-button back-button">
            <i class="fa fa-arrow-left"></i> New Search
          </router-link>
        </div>
      </div>

      <!-- Right Column - Results Grid -->
      <div class="right-column">
        <div
          class="results-grid"
          v-if="!error && filteredResults.length"
        >
          <div
            v-for="(image, index) in filteredResults"
            :key="index"
            class="result-card"
            :class="{ 'premium-user': isPremium }"
          >
            <div class="image-container">
              <div
                class="blur-background"
                :style="getBlurBackgroundStyle(image)"
                v-if="!isPremium"
              ></div>
              <img
                ref="resultImage"
                :src="`https://storage.googleapis.com/similarity-finder/${image.storage_key}`"
                :alt="image.title"
                class="top-image"
                @load="imageLoaded($event, image)"
                :style="isPremium ? {} : getUnblurredMaskStyle(image)"
              />
              <div class="image-overlay">
                <div class="link-container">
                  <i v-if="!isPremium" class="fa fa-external-link-alt"></i>
                  <a v-if="isPremium" :href="image.profile_url" target="_blank" rel="noopener noreferrer" class="profile-link">
                    <i class="fa fa-external-link-alt"></i> Visit Profile
                  </a>
                </div>
              </div>
              <div class="url-badge" v-if="!isPremium">
                {{ getFormattedUrl(image.profile_url) }}
              </div>
              <div class="unlock-button" v-if="!isPremium" @click="showLoginModal('upgrade')">
                <i class="fa fa-unlock"></i> Unlock
              </div>
            </div>
            <div class="image-info">
              <div class="facespy-badge" :class="{ premium: isPremium }" v-if="image.distance > MATCH_DISTANCE">
                Match
              </div>
              <div v-if="isPremium" class="profile-title">
                <template v-if="image.title">{{ image.title }}</template>
                <template v-else-if="image.user">{{ image.user }}</template>
                <template v-else-if="image.profile_url">{{ getProfileName(image.profile_url) }}</template>
                <template v-else>{{ selectedTab === "of" ? "OnlyFans Profile" : "Profile" }}</template>
              </div>
              <div class="image-source">
                {{ selectedTab === "of" ? "OnlyFans" : "Adult Movies" }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="empty-state"
          v-if="
            !loading[selectedTab] &&
            !error &&
            filteredResults.length === 0
          "
        >
          <div class="empty-icon">
            <i class="fa fa-search"></i>
          </div>
          <h3>No Matches Found</h3>
          <p v-if="showOnlyMatches && values[selectedTab]?.data?.length">Try turning off the "Show only matches" filter for more results.</p>
          <p v-else>We couldn't find any matches for this image in our database.</p>
        </div>

        <div
          class="loading-state"
          v-if="loading[selectedTab]"
        >
          <div class="loading-animation">
            <i class="fa fa-circle-notch fa-spin"></i>
          </div>
          <h3>FaceSpy is searching...</h3>
          <p>This may take a few moments, please wait.</p>
          
          <!-- Face mosaic animation container -->
          <div class="face-analysis-container">
            <div class="mosaic-container" :style="{ backgroundPosition: `${mosaicPosition}px 0` }">
              <div class="scanning-line"></div>
              <div class="scanning-overlay"></div>
              <div class="scanning-highlight"></div>
            </div>
            <div class="analysis-label">Analyzing Faces</div>
          </div>
          
          <div class="progress-container">
            <div class="progress-bar" :style="{ width: `${searchProgress}%` }"></div>
          </div>
          <div class="progress-text">{{ Math.round(searchProgress) }}%</div>
        </div>

        <div class="error-state" v-if="error">
          <div class="error-icon">
            <i class="fa fa-exclamation-triangle"></i>
          </div>
          <h3>Oops! Something went wrong</h3>
          <p>
            FaceSpy encountered an error while processing your request. Please try
            again.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import api from "@/services/api";
import FileStore from "@/services/FileStore";
import AuthStore from "@/services/AuthStore";
import GoogleAuthService from "@/services/GoogleAuthService";
import PremiumService from "@/services/PremiumService";
import EventBus from "@/services/EventBus";

// Match threshold constant
const MATCH_DISTANCE = 0.38;

export default defineComponent({
  inject: ["mixpanel"],
  name: "results-page",
  data() {
    return {
      files: [],
      loading: {
        of: true,
        psos: false,
      },
      values: {
        of: null,
        psos: null,
      },
      requestsSent: {
        of: false,
        psos: false,
      },
      selectedTab: "of",
      error: false,
      searchProgress: 0,
      realFetchComplete: {
        of: false,
        psos: false,
      },
      progressInterval: null,
      mosaicPosition: 0,
      mosaicAnimationInterval: null,
      faceWidth: 96, // Width of each face in the mosaic
      totalFaces: 19, // Total number of faces in the mosaic
      showOnlyMatches: false, // New filter toggle
      currentImageIndex: 0,
      fileUrlCache: {}, // Cache for file URLs to avoid recreating blob URLs
      mosaicAnimationStarted: false,
      MATCH_DISTANCE: MATCH_DISTANCE, // Make constant available in template
    };
  },
  computed: {
    totalResults() {
      if (!this.values[this.selectedTab]) return 0;
      return this.values[this.selectedTab].data?.length || 0;
    },
    isLoggedIn() {
      return AuthStore.isLoggedIn();
    },
    isPremium() {
      return PremiumService.isPremiumUser();
    },
    filteredResults() {
      if (!this.values[this.selectedTab] || !this.values[this.selectedTab].data) return [];
      
      if (this.showOnlyMatches) {
        return this.values[this.selectedTab].data.filter(image => image.distance > MATCH_DISTANCE);
      } else {
        return this.values[this.selectedTab].data;
      }
    }
  },
  methods: {
    imageLoaded(event, image) {
      const img = event.target;
      image.displayWidth = img.clientWidth;
      image.displayHeight = img.clientHeight;
      this.$forceUpdate();
    },
    getBlurBackgroundStyle(image) {
      if (!image.displayWidth || !image.displayHeight) {
        return { display: "none" };
      }
      return {
        backgroundImage: `url("https://storage.googleapis.com/similarity-finder/${image.storage_key}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: "blur(8px)",
        position: "absolute",
        top: 0,
        left: 0,
        width: image.displayWidth + "px",
        height: image.displayHeight + "px",
        zIndex: 1,
      };
    },
    getUnblurredMaskStyle(image) {
      if (!image.displayWidth || !image.displayHeight) {
        return {};
      }
      const widthRatio = image.displayWidth / 640;
      const heightRatio = image.displayHeight / 640;
      const x1 = image.bbox1 * widthRatio;
      const y1 = image.bbox2 * heightRatio;
      const x2 = image.bbox3 * widthRatio;
      const y2 = image.bbox4 * heightRatio;
      const faceCenterX = x1 + (x2 - x1) / 2;
      const faceCenterY = y1 + (y2 - y1) / 2;
      const faceWidth = x2 - x1;
      const faceHeight = y2 - y1;
      const maxFaceDimension = Math.max(faceWidth, faceHeight);

      // Create a more consistent blur transition by using a base value plus a small
      // proportion of the face size, constrained between min and max values
      const baseRadius =
        Math.min(image.displayWidth, image.displayHeight) * 0.15; // Base is 15% of the smaller image dimension
      const faceContribution = maxFaceDimension * 0.5; // Only add 50% of face size to the radius
      const minRadius = Math.min(image.displayWidth, image.displayHeight) * 0.1; // Minimum radius is 10% of smaller dimension
      const maxRadius =
        Math.min(image.displayWidth, image.displayHeight) * 0.25; // Maximum radius is 25% of smaller dimension

      // Constrain the final radius between min and max values
      const fadeRadius = Math.max(
        minRadius,
        Math.min(maxRadius, baseRadius + faceContribution)
      );

      const unblurredRadius = maxFaceDimension * 0.6; // Make the fully unblurred area slightly smaller than face
      const cxPercent = (faceCenterX / image.displayWidth) * 100;
      const cyPercent = (faceCenterY / image.displayHeight) * 100;

      // Create a smoother gradient with multiple steps between unblurred and fully blurred
      const transitionStart = unblurredRadius * 0.95; // Slightly inside the unblurred radius to soften edge
      const step1 = unblurredRadius + (fadeRadius - unblurredRadius) * 0.2;
      const step2 = unblurredRadius + (fadeRadius - unblurredRadius) * 0.4;
      const step3 = unblurredRadius + (fadeRadius - unblurredRadius) * 0.6;
      const step4 = unblurredRadius + (fadeRadius - unblurredRadius) * 0.8;

      const gradientMask = `radial-gradient(circle at ${cxPercent}% ${cyPercent}%, 
        rgba(0,0,0,1) 0%, 
        rgba(0,0,0,1) ${transitionStart}px, 
        rgba(0,0,0,0.9) ${unblurredRadius}px, 
        rgba(0,0,0,0.7) ${step1}px,
        rgba(0,0,0,0.5) ${step2}px,
        rgba(0,0,0,0.3) ${step3}px,
        rgba(0,0,0,0.1) ${step4}px,
        rgba(0,0,0,0) ${fadeRadius}px)`;

      return {
        position: "relative",
        zIndex: 2,
        maskImage: gradientMask,
        WebkitMaskImage: gradientMask,
      };
    },
    selectTab(index) {
      this.selectedTab = index;
      this.mixpanel.track("tab_selected", {
        tab: index,
      });
      this.fetch();
    },
    startProgressAnimation(tab) {
      // Clear any existing intervals
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
      }
      if (this.mosaicAnimationInterval) {
        clearInterval(this.mosaicAnimationInterval);
      }
      
      // Reset progress
      this.searchProgress = 0;
      this.mosaicPosition = 0;
      
      // Generate random duration between 6 and 10 seconds
      const duration = Math.random() * 4000 + 6000; // 6000-10000ms
      const interval = 50; // Update every 50ms
      const steps = duration / interval;
      const increment = 100 / steps;
      
      // Start face mosaic animation only once
      if (!this.mosaicAnimationStarted) {
        this.startMosaicAnimation();
        this.mosaicAnimationStarted = true;
      }
      
      let apiResult = null; // Store API result for later use
      
      // Start progress animation
      this.progressInterval = setInterval(() => {
        this.searchProgress += increment;
        
        // When progress reaches 100%
        if (this.searchProgress >= 100) {
          this.searchProgress = 100;
          clearInterval(this.progressInterval);
          
          // Stop face animation
          if (this.mosaicAnimationInterval) {
            clearInterval(this.mosaicAnimationInterval);
            this.mosaicAnimationStarted = false;
          }
          
          // If the real fetch is complete, update UI
          if (this.realFetchComplete[tab]) {
            // If we have a stored API result, use it
            if (apiResult) {
              this.completeSearch(tab, apiResult);
            } 
            // If we've received results through values, show them
            else if (this.values[tab]) {
              this.loading[tab] = false;
            }
            // If we have an explicit error, show it 
            else if (this.error) {
              this.loading[tab] = false;
            }
            // Otherwise, wait in case results are still coming
            else {
              setTimeout(() => {
                this.loading[tab] = false;
                if (!this.values[tab]) {
                  this.error = true;
                }
              }, 200);
            }
          }
        }
      }, interval);
      
      // Create a method to capture API result when it arrives
      this.captureApiResult = (result) => {
        apiResult = result;
        
        // If progress is already complete, update UI
        if (this.searchProgress >= 100) {
          this.completeSearch(tab, result);
        }
      };
    },
    
    startMosaicAnimation() {
      // Calculate total width of the mosaic
      const totalWidth = this.faceWidth * this.totalFaces;
      
      // Create animation that cycles through the faces
      this.mosaicAnimationInterval = setInterval(() => {
        // Move to the next face
        this.mosaicPosition -= this.faceWidth;
        
        // If we've gone through all faces, reset to the beginning
        if (Math.abs(this.mosaicPosition) >= totalWidth - this.faceWidth) {
          this.mosaicPosition = 0;
        }
      }, 700); // Change face every 700ms (slowed down from 400ms)
    },
    
    fetch() {
      if (
        this.values[this.selectedTab] ||
        this.requestsSent[this.selectedTab] ||
        this.files.length === 0
      )
        return;
      
      this.loading[this.selectedTab] = true;
      this.requestsSent[this.selectedTab] = true;
      this.searchProgress = 0;
      this.error = false; // Reset error state when starting a new fetch
      
      // Start the progress animation
      this.startProgressAnimation(this.selectedTab);
      
      const formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i]);
      }
      
      api
        .post(`/find-similar/${this.selectedTab}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log("API response successful:", response.data);
          this.realFetchComplete[this.selectedTab] = true;
          
          // Pass result to the capture method instead of storing directly
          if (this.captureApiResult) {
            this.captureApiResult(response.data);
          } else {
            // Fallback in case captureApiResult is not available
            if (this.searchProgress >= 100) {
              this.completeSearch(this.selectedTab, response.data);
            } else {
              // Store temporarily until progress completes
              this.values[this.selectedTab] = response.data;
            }
          }
        })
        .catch((error) => {
          // console.error("Error fetching results:", error);
          this.realFetchComplete[this.selectedTab] = true;
          this.error = true;
          
          if (this.searchProgress >= 100) {
            this.loading[this.selectedTab] = false;
          }
        });
    },
    
    completeSearch(tab, result) {
      // console.log("Completing search with result:", result);
      this.loading[tab] = false;
      this.values[tab] = result;
      this.values = Object.assign({}, this.values);
      this.mixpanel.track("similar_images_found", {
        count: this.values[tab]?.data?.length || 0,
        source: tab,
      });
      this.error = false;
    },
    getFormattedUrl(url) {
      // Return the entire URL instead of just the hostname
      return url;
    },
    getProfileName(url) {
      // Extract profile name from URL
      try {
        if (!url) return "Profile";
        
        // For OnlyFans URLs
        if (url.includes('onlyfans.com')) {
          const parts = url.split('/');
          return parts[parts.length - 1] || "OnlyFans Profile";
        }
        
        // For other URLs
        const urlObj = new URL(url);
        const pathParts = urlObj.pathname.split('/').filter(p => p);
        
        // Return last non-empty path part or hostname
        return pathParts.length > 0 ? pathParts[pathParts.length - 1] : urlObj.hostname;
      } catch (e) {
        // If URL parsing fails, just return the URL or a fallback
        return url || "Profile";
      }
    },
    showLoginModal(source) {
      // Since we can't directly access methods on $root in Vue 3,
      // we'll use the router to pass this information
      this.$router.replace({
        query: {
          ...this.$route.query,
          showLogin: "true",
          loginSource: source,
        },
      });

      this.mixpanel.track("unlock_button_clicked", {
        source: this.selectedTab,
        login_source: source,
      });
    },
    setupEventListeners() {
      // Listen for premium status changes
      EventBus.on("premium-status-change", (isPremium) => {
        // Force component re-render when premium status changes
        this.$forceUpdate();
      });
    },
    getFilePreviewUrl(file) {
      // Use cached URL if exists, otherwise create and cache a new one
      const fileId = file.name + file.size + file.lastModified;
      if (!this.fileUrlCache[fileId]) {
        this.fileUrlCache[fileId] = URL.createObjectURL(file);
      }
      return this.fileUrlCache[fileId];
    },
    getFileName(file) {
      // Extract just the file name without the path
      return file.name.split('\\').pop().split('/').pop();
    },
    prevImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      }
    },
    nextImage() {
      if (this.currentImageIndex < this.files.length - 1) {
        this.currentImageIndex++;
      }
    },
    preloadMosaicImage() {
      const img = new Image();
      img.src = 'https://storage.googleapis.com/similar-finder/static/mosaic.png';
    },
  },
  watch: {
    showOnlyMatches() {
      this.mixpanel.track("filter_toggled", {
        show_only_matches: this.showOnlyMatches
      });
    }
  },
  async mounted() {
    // Set up event listeners
    this.setupEventListeners();
    
    // Preload the mosaic image for animation
    this.preloadMosaicImage();
    
    // Load files from the store
    this.files = FileStore.getFiles();
    if (this.files.length === 0) {
      this.$router.push("/");
      return;
    }
    
    // Load premium status
    if (this.isLoggedIn) {
      await PremiumService.loadPremiumStatus();
    }
    
    // Track page view and start search
    this.mixpanel.track("results_page_opened");
    this.fetch();
  },
  beforeUnmount() {
    // Clear intervals when component is unmounted
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
    }
    if (this.mosaicAnimationInterval) {
      clearInterval(this.mosaicAnimationInterval);
    }
    
    // Clean up event listeners
    EventBus.off('premium-status-change');
    
    // Revoke all cached object URLs to prevent memory leaks
    Object.values(this.fileUrlCache).forEach(url => {
      URL.revokeObjectURL(url);
    });
    this.fileUrlCache = {};
  }
});
</script>

<style scoped>
.left-actions {
  margin-top: 20px;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 20px;
  background: rgba(255, 255, 255, 0.08);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
}

.action-button:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.results-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}

.content-columns {
  display: flex;
  gap: 24px;
}

.left-column {
  flex: 0 0 260px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.right-column {
  flex: 1;
  min-width: 0; /* Ensures the column can shrink below its content size */
}

.input-files-section {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  padding: 12px;
  border: 1px solid rgba(74, 116, 220, 0.2);
}

.section-title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
}

.section-title i {
  color: #4a74dc;
}

.input-files-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 300px;
  overflow-y: auto;
  padding: 4px 0;
  scrollbar-width: thin;
  scrollbar-color: rgba(74, 116, 220, 0.5) rgba(255, 255, 255, 0.1);
}

.input-carousel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.carousel-navigation {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-navigation.left {
  left: 10px;
}

.carousel-navigation.right {
  right: 10px;
}

.carousel-navigation:hover {
  transform: translateY(-50%) scale(1.2);
}

.carousel-navigation i {
  font-size: 1.1rem;
  color: white;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.image-counter {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 0.75rem;
  z-index: 10;
}

.input-file-card {
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.input-file-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.input-image-container {
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  position: relative;
  background-color: rgba(0, 0, 0, 0.1);
}

.input-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.input-file-info {
  padding: 8px 10px;
}

.input-file-name {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.9);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-navigation {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  padding: 12px;
  border: 1px solid rgba(74, 116, 220, 0.2);
}

.tab-item {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10px 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.05);
  font-size: 0.9rem;
}

.tab-item.active {
  color: white;
  background: rgba(74, 116, 220, 0.2);
  border-left: 3px solid #4a74dc;
}

.filter-section {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  padding: 12px;
  border: 1px solid rgba(74, 116, 220, 0.2);
}

.filter-toggle {
  display: flex;
  align-items: center;
}

.toggle-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.toggle-label input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 18px;
  transition: all 0.3s;
  margin-right: 8px;
}

.toggle-switch:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.3s;
}

input:checked + .toggle-switch {
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
}

input:checked + .toggle-switch:before {
  transform: translateX(18px);
}

.toggle-text {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.85rem;
}

.results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
}

.result-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  max-width: 220px;
  margin: 0 auto;
  width: 100%;
}

.result-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
}

.image-container {
  position: relative;
  aspect-ratio: 3/4;
  overflow: hidden;
}

.blur-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.top-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 15px;
  z-index: 4;
}

.link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 100%;
}

.link-container i {
  font-size: 1.1rem;
  color: white;
}

.image-container:hover .image-overlay {
  opacity: 1;
}

/* Remove the special treatment for premium users - only show overlay on hover */
.profile-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration: none;
  color: white;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  z-index: 10;
  transition: transform 0.2s ease;
  margin: 0 auto;
  width: 60%;
  max-width: 150px;
}

.profile-link:hover {
  transform: translateY(-2px);
}

.url-badge {
  position: absolute;
  bottom: 16px;
  left: 8px;
  right: auto;
  top: auto;
  background-color: white;
  color: #333;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.65rem;
  font-weight: 500;
  z-index: 10;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  opacity: 0.6;
}

.image-info {
  padding: 12px;
  position: relative;
}

.facespy-badge {
  position: absolute;
  top: -10px;
  right: 8px;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: white;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 0.65rem;
  font-weight: 600;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.facespy-badge.premium {
  background: linear-gradient(135deg, #ffd700 0%, #ff8c00 100%);
  color: #333;
  box-shadow: 0 2px 8px rgba(255, 215, 0, 0.4);
}

.image-source {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
}

.profile-title {
  margin-top: 10px;
  margin-bottom: 6px;
  font-size: 0.9rem;
  font-weight: 600;
  color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  padding: 0 4px;
  border-left: 3px solid #4a74dc;
  letter-spacing: 0.2px;
}

.empty-state,
.loading-state,
.error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 16px;
  text-align: center;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  margin-bottom: 32px;
  border: 1px solid rgba(74, 116, 220, 0.2);
}

.empty-icon,
.loading-animation,
.error-icon {
  font-size: 2.5rem;
  margin-bottom: 16px;
  opacity: 0.6;
}

.loading-animation {
  color: #4a74dc;
}

.error-icon {
  color: #c33c65;
}

.empty-state h3,
.loading-state h3,
.error-state h3 {
  font-size: 1.3rem;
  margin: 0 0 8px;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.empty-state p,
.loading-state p,
.error-state p {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  max-width: 400px;
}

.back-button {
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
}

.back-button:hover {
  background: linear-gradient(135deg, #3e63c0 0%, #b23459 100%);
}

.unlock-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 10;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.unlock-button:hover {
  transform: translateY(-2px);
}

.face-analysis-container {
  width: 80px;
  height: 80px;
  margin: 16px auto;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 24px rgba(74, 116, 220, 0.6);
  transform: scale(1.4);
}

.mosaic-container {
  width: 100%;
  height: 100%;
  background-image: url('https://storage.googleapis.com/similar-finder/static/mosaic.png');
  background-size: 1824px 100%; /* 96px * 19 faces = 1824px */
  background-repeat: repeat-x;
  position: relative;
  transition: background-position 0.6s ease-in-out;
}

.scanning-line {
  position: absolute;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, rgba(0, 98, 255, 0), rgba(0, 149, 255, 1) 50%, rgba(0, 98, 255, 0));
  top: 0;
  left: 0;
  box-shadow: 0 0 16px 6px rgba(0, 149, 255, 1);
  animation: scanFace 1.8s ease-in-out infinite;
  opacity: 0.95;
  z-index: 3;
}

.scanning-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, 
    rgba(0, 149, 255, 0.15) 0%,
    rgba(0, 149, 255, 0) 20%,
    rgba(0, 149, 255, 0) 80%,
    rgba(0, 149, 255, 0.15) 100%);
  animation: overlayPulse 3s ease-in-out infinite;
  z-index: 1;
}

.scanning-highlight {
  position: absolute;
  width: 100%;
  height: 40%;
  background: linear-gradient(to bottom,
    rgba(0, 149, 255, 0.1) 0%,
    rgba(0, 149, 255, 0.4) 50%,
    rgba(0, 149, 255, 0.1) 100%);
  top: 0;
  left: 0;
  animation: highlightScan 1.8s ease-in-out infinite;
  opacity: 0.85;
  z-index: 2;
}

@keyframes scanFace {
  0% {
    top: 0;
    opacity: 0.8;
  }
  15% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    top: calc(100% - 5px);
    opacity: 0.8;
  }
}

@keyframes highlightScan {
  0% {
    top: -40%;
    opacity: 0.85;
  }
  100% {
    top: 100%;
    opacity: 0.85;
  }
}

@keyframes overlayPulse {
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.8;
  }
}

.analysis-label {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 14px;
  text-align: center;
  text-shadow: 0 0 8px rgba(0, 149, 255, 0.5);
  font-weight: 500;
}

.progress-container {
  width: 80%;
  max-width: 400px;
  height: 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  overflow: hidden;
  margin: 16px 0 8px;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #4a74dc, #c33c65);
  border-radius: 6px;
  transition: width 0.3s ease;
}

.progress-text {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 16px;
}

.image-counter {
  text-align: center;
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 992px) {
  .content-columns {
    flex-direction: column;
  }
  
  .left-column {
    flex: 0 0 auto;
    width: 100%;
  }
  
  .input-files-container {
    flex-direction: column;
    max-height: none;
    overflow-y: visible;
  }
  
  .input-carousel {
    width: 100%;
    justify-content: center;
  }
  
  .input-file-card {
    width: 75%;
    max-width: 260px;
  }
  
  .tab-navigation {
    flex-direction: row;
  }
  
  .tab-item.active {
    border-left: none;
    border-bottom: 3px solid #4a74dc;
  }
}

@media (max-width: 576px) {
  .results-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 12px;
  }
}

@media (max-width: 480px) {
  .results-container {
    padding: 16px 12px;
  }
  
  .results-grid {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}

@media (max-width: 375px) {
  .results-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .url-badge {
    max-width: 85%;
  }
}
</style>